import React, { useState, useContext, useRef } from "react";
import { constants, translations } from "../../base.js";
import { AuthContext } from "../../context/authContext.js";
import { CommentApi } from "../../api/commentApi.js";

const NewsCommentForm = ({ newsId, formSubmitted }) => {
    const [inputs, setInputs] = useState({ content: "" });
    const [errorMessage, setError] = useState("");
    const { currentUser } = useContext(AuthContext);
    const commentRef = useRef(null);

    const { addNewsComment } = CommentApi;

    const handleChange = (e) => {
        setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const resetForm = () => {
        Object.keys(inputs).forEach((key) => {
            setInputs((prevInputs) => ({ ...prevInputs, [key]: "" }));
        });

        commentRef.current.value = "";
    };

    const checkContent = () => {
        return (
            inputs["content"].length >= constants.VALIDATION.MIN_CONTENT_SIZE
        );
    };

    const handleFormSubmit = async (e) => {
        e.target.disabled = true;
        e.preventDefault();

        if (!checkContent()) {
            setError(translations.Errors.ContentTooShort);
            return;
        }

        const result = await addNewsComment(
            inputs.content,
            newsId,
            currentUser?.id
        );

        if (!result?.error) {
            resetForm();
            formSubmitted(true);
        } else {
            setError(result.error);
        }
    };

    return (
        <>
            <form className="comment-form">
                <textarea
                    name="content"
                    onKeyUp={handleChange}
                    ref={commentRef}
                    autoFocus
                    placeholder={translations.Forms.WriteSomething}
                ></textarea>
                <div className="actions">
                    <button
                        className="submit small"
                        onClick={handleFormSubmit}
                        disabled={!checkContent()}
                    >
                        {translations.Forms.Send}
                    </button>
                </div>
            </form>
            {errorMessage && <div className="error">{errorMessage}</div>}
        </>
    );
};

export default NewsCommentForm;
