import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { translations, constants } from "../base.js";
import { ParentApi } from "../api/parentApi.js";
import { AuthContext } from "../context/authContext.js";
import Parent from "../components/parent/Parent.jsx";
import ParentForm from "../components/parent/ParentForm.jsx";
import ParentChildren from "../components/parent/ParentChildren.jsx";
import DeleteParentModal from "../components/parent/DeleteParentModal.jsx";
import Spinner from "../components/common/Spinner.jsx";
import NoItemsCard from "../components/common/NoItemsCard.jsx";

const ParentsPageBase = ({
    pageTitle,
    showOrderMenu = false,
    showSidebar = false,
    widgetsComponent: Widgets = null,
    roomId = null,
}) => {
    const typeRecent = constants.PARENT_TYPE.RECENT;
    const typeRandom = constants.PARENT_TYPE.RANDOM;

    const [records, setRecords] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [isEndOfList, setIsEndOfList] = useState(false);
    const [isUnavailable, setIsUnavailable] = useState(false);
    const [lastLoadedId, setLastLoadedId] = useState(0);
    const [trigger, setTrigger] = useState(false);
    const { type } = useParams();
    const [dynamicType, setDynamicType] = useState(
        type ? parseInt(type, 10) : typeRecent
    );
    const [selectedParentId, setSelectedParentId] = useState(null);
    const [isShowChildren, setIsShowChildren] = useState(false);
    const [isParentChildrenAutoFocus, setIsParentChildrenAutoFocus] =
        useState(false);
    const [parentToDelete, setParentToDelete] = useState(null);
    const { currentUser } = useContext(AuthContext);
    const { getParents, getRandomParents, deleteParent } = ParentApi;

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setIsUnavailable(false);

            const result =
                dynamicType === typeRandom
                    ? await getRandomParents()
                    : await getParents(
                          roomId,
                          constants.DEFAULTS.PAGE_SIZE,
                          lastLoadedId
                      );

            if (!result.error) {
                if (result.length > 0) {
                    if (dynamicType === typeRandom) {
                        setRecords(result);
                    } else {
                        setRecords((prevRecords) => [
                            ...prevRecords,
                            ...result,
                        ]);
                    }
                }

                if (result.length < constants.DEFAULTS.PAGE_SIZE) {
                    // No more records
                    setIsEndOfList(true);
                }
            } else {
                setIsUnavailable(true);
            }

            setIsLoading(false);
        };

        fetchData();
    }, [lastLoadedId, getParents, getRandomParents, trigger, dynamicType]);

    const handleLoadMore = () => {
        setTrigger((prev) => !prev);
        setLastLoadedId(
            records?.length > 0 ? records[records.length - 1].id : 0
        );
    };

    const handleFormSubmitted = (success) => {
        if (success) {
            setRecords([]);
            setLastLoadedId(-Math.random());
        }
    };

    const handleShowChildren = (id, isAutoFocus = false) => {
        setSelectedParentId(id);
        setIsShowChildren(true);
        setIsParentChildrenAutoFocus(isAutoFocus);
        document.body.classList.add("no-scroll");
    };

    const handleHideChildren = () => {
        setIsShowChildren(false);
        document.body.classList.remove("no-scroll");
    };

    const setRecentOrder = () => {
        setRecords([]);
        setLastLoadedId(-Math.random());
        setDynamicType(typeRecent);
    };

    const setRandomOrder = () => {
        setRecords([]);
        if (dynamicType === typeRandom) {
            setTrigger((prev) => !prev);
        } else {
            setDynamicType(typeRandom);
        }
    };

    // Sets parent with specified id for removal
    const handleDeleteParent = (id) => {
        const selected = records.find((record) => record.id === id);
        setParentToDelete(selected);
    };

    // Closes modal
    const handleCloseModal = () => {
        setParentToDelete(null);
    };

    // Marks selected parent as deleted when confirmed in modal popup
    const handleDeleteComfirmed = async (e) => {
        if (parentToDelete) {
            const result = await deleteParent(parentToDelete.id, roomId);

            if (!result?.error) {
                const updatedRecords = records.filter(
                    (record) => record.id !== parentToDelete.id
                );

                setRecords(updatedRecords);
                setIsEndOfList(updatedRecords.length === 0);
            }
        }

        setParentToDelete(null);
    };

    return (
        <div className="two-column-container">
            <div className="parents-content">
                <h1>{pageTitle}</h1>

                <div className="page-menu">
                    {showOrderMenu && (
                        <div className="menu-item">
                            <span
                                className={
                                    dynamicType === typeRecent ? "active" : ""
                                }
                                onClick={!isLoading ? setRecentOrder : null}
                            >
                                {translations.Parents.Recent}
                            </span>
                        </div>
                    )}

                    {showOrderMenu && (
                        <div className="menu-item">
                            <span
                                className={
                                    dynamicType === typeRandom
                                        ? "active trigger"
                                        : ""
                                }
                                onClick={!isLoading ? setRandomOrder : null}
                            >
                                {translations.Parents.Random}
                            </span>
                        </div>
                    )}

                    <div className="menu-item">
                        {currentUser && (
                            <ParentForm
                                formSubmitted={handleFormSubmitted}
                                roomId={roomId}
                            />
                        )}
                    </div>
                </div>

                {parentToDelete && (
                    <DeleteParentModal
                        parent={parentToDelete}
                        closeModal={handleCloseModal}
                        confirmDelete={handleDeleteComfirmed}
                    />
                )}

                {isShowChildren && (
                    <ParentChildren
                        id={selectedParentId}
                        roomId={roomId}
                        hideChildren={handleHideChildren}
                        isAutoFocus={isParentChildrenAutoFocus}
                    />
                )}

                {records.map((record) => (
                    <Parent
                        key={record.id}
                        parent={record}
                        parentChildrenCnt={record.childrenCnt}
                        showChildren={handleShowChildren}
                        deleteParent={handleDeleteParent}
                    />
                ))}

                {isLoading && <Spinner />}

                {isUnavailable && (
                    <div className="error-message-block">
                        {translations.Errors.ServiceIsTemporaryUnavalable}
                    </div>
                )}

                {dynamicType === constants.PARENT_TYPE.RECENT &&
                    !isLoading &&
                    !isEndOfList && (
                        <div className="filter-buttons">
                            <button onClick={handleLoadMore}>
                                {translations.Buttons.LoadMore}
                            </button>
                        </div>
                    )}

                {!isLoading && isEndOfList && (
                    <NoItemsCard isEmpty={records?.length === 0} />
                )}
            </div>
            {showSidebar && (
                <div className="right-sidebar">
                    {Widgets !== null && <Widgets roomId={roomId} />}
                </div>
            )}
        </div>
    );
};

export default ParentsPageBase;
