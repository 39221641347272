import React, { createContext, useEffect, useState } from "react";
import { SERVICE_URL, constants } from "../base.js";
import axios from "axios";
import { getSetting, setSetting } from "./../utils/settings.js";

export const AuthContext = createContext();

// Create an axios instance with headers for all requests
const apiClient = axios.create({
    baseURL: SERVICE_URL,
    headers: {
        "x-api-key": constants.API_KEY,
    },
});

export const AuthContextProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(
        JSON.parse(getSetting("currentUser") || null)
    );

    /**
     * Logs in user
     *
     * @param {string} email
     * @param {string} password
     */
    const login = async (email, password) => {
        const endpointUrl = `${SERVICE_URL}/auth/login`;
        const params = { email: email, password: password };
        const options = { withCredentials: true };

        const result = await apiClient.post(endpointUrl, params, options);

        setCurrentUser(result.data);

        return result.data;
    };

    /**
     * Logs out user
     *
     */
    const logout = async () => {
        await apiClient.post(`${SERVICE_URL}/auth/logout`);
        setCurrentUser(null);
    };

    /**
     * Creates a new user account and sends the confirmation email
     *
     * @param {string} nick
     * @param {string} email
     * @param {string} password
     * @return {Object} result
     */
    const register = async (nick, email, password) => {
        const endpointUrl = `${SERVICE_URL}/auth/register`;
        const params = { nick: nick, email: email, password: password };

        const res = await apiClient.post(endpointUrl, params);

        setCurrentUser(null);
        return res;
    };

    /**
     * Changes current user's password
     *
     * @param {string} email
     * @param {string} password
     * @param {string} newpassword
     * @return {Object} result
     */
    const changePassword = async (email, password, newPassword) => {
        const endpointUrl = `${SERVICE_URL}/auth/changepassword`;
        const params = {
            email: email,
            password: password,
            newpassword: newPassword,
        };

        const res = await apiClient.post(endpointUrl, params);

        setCurrentUser(null);
        return res;
    };

    /**
     * Resets user's password
     *
     * @param {string} email
     * @param {string} newpassword
     * @return {Object} result
     */
    const resetPassword = async (email, newPassword) => {
        const endpointUrl = `${SERVICE_URL}/auth/resetpassword`;
        const params = { email: email, newpassword: newPassword };

        const res = await apiClient.post(endpointUrl, params);
        setCurrentUser(null);
        return res;
    };

    /**
     * Confirms resetting user's password and activates the user
     *
     * @param {string} hash
     * @param {string} email
     * @return {Object} result
     */
    const confirmResetPassword = async (hash, email) => {
        const endpointUrl = `${SERVICE_URL}/auth/confirmresetpassword/`;
        const params = {
            params: { hash: encodeURIComponent(hash), email: email },
        };

        const res = await apiClient.get(endpointUrl, params);

        setCurrentUser(null);
        return res;
    };

    /**
     * Confirms registration email
     *
     * @param {string} hash
     * @param {string} email
     * @return {Object} result
     */
    const confirmEmail = async (hash, email) => {
        const endpointUrl = `${SERVICE_URL}/auth/confirm/`;
        const params = {
            params: { hash: encodeURIComponent(hash), email: email },
        };

        const res = await apiClient.get(endpointUrl, params);

        setCurrentUser(null);
        return res;
    };

    /**
     * Refresh current user
     *
     */
    const refreshUserData = async (email) => {
        const endpointUrl = `${SERVICE_URL}/auth/getCurrentUser`;
        const params = {
            params: { email: email },
        };

        const res = await apiClient.get(endpointUrl, params);

        setCurrentUser(res.data);

        return res;
    };

    useEffect(() => {
        setSetting("currentUser", JSON.stringify(currentUser));
    }, [currentUser]);

    return (
        <AuthContext.Provider
            value={{
                currentUser,
                register,
                login,
                logout,
                changePassword,
                resetPassword,
                confirmResetPassword,
                confirmEmail,
                refreshUserData,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
